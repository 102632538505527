import React, { Suspense, lazy } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

//Intersection observer - this might be removed with later versions of Safari
import "intersection-observer/intersection-observer.js"

import * as ROUTES from "constants/routes"

import "semantic-ui-css/semantic.min.css"
import "styles/font.css"
import "styles/common-style.scss"

import Navigation from "components/Main/navigation"
//import BurgerButton from "components/App/burgerButton.js"
import NotFound from "components/Main/notFound.js"

import ReserveSpot from "components/App/reserveSpot"
import ReservationsOverview from "components/App/reservationsOverview"

import { withAuthentication, AuthUserContext } from "shared-components/Session"
import Firebase, { withFirebase, User, Language } from "components/Firebase"

import { IntlProvider } from "react-intl"
import strings_en from "shared-components/internationalization/strings_en.json"
import strings_cz from "shared-components/internationalization/strings_cz.json"
//import strings_sk from 'shared-components/internationalization/strings_sk.json';
//import strings_hu from 'shared-components/internationalization/strings_hu.json';

import logo from "assets/logo-black.svg"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { ThemeProvider } from "@mui/material"
import muiTheme from "otherFiles/muiTheme"
import CookieBanner from "otherFiles/cookie-banner"
import SAP from "components/Statics/sap"

const CancelReservationPage = lazy(() => import("components/App/cancelReservation"))
const AuthorizeSlackPage = lazy(() => import("shared-components/User/authorizeSlack"))

const AdminPage = lazy(() => import("components/Admin/admin"))
const VisitorsBookingPage = lazy(() => import("components/Admin/visitorsBooking"))
const VisitorsBookingPreviewPage = lazy(() => import("components/Admin/visitorsBookingPreview"))
const AnalyticsPage = lazy(() => import("components/Admin/analytics"))
const AbuseReportPage = lazy(() => import("components/Admin/abuseReport"))
const UserManagement = lazy(() => import("shared-components/Admin/userManagement"))
const ParkingManagement = lazy(() => import("components/Admin/parkingManagement"))

const Profile = lazy(() => import("shared-components/User/profile.js"))
const EditProfile = lazy(() => import("shared-components/User/editProfile"))
const SignInPage = lazy(() => import("shared-components/User/signIn.js"))
const SignUpPage = lazy(() => import("shared-components/User/signUp"))
const CreateCars = lazy(() => import("shared-components/User/createCars"))

const Onboarding = lazy(() => import("components/Admin/onboarding"))

const AboutPage = lazy(() => import("shared-components/Statics/about.js"))
const ContactsPage = lazy(() => import("shared-components/Statics/contacts"))

const KiwiRules = lazy(() => import("components/Statics/kiwiRules"))

const messages = {
    en: strings_en,
    cs: strings_cz
    //'sk': strings_sk,
    //'hu': strings_hu,
}
const i18nConfig = {
    defaultLocale: "en",
    messages
}
const languages: Language[] = [
    new Language("cs", "🇨🇿 Česky"),
    new Language("en", "🇬🇧 English")
    //new Language('sk', '🇸🇰 Slovensky'),
    //new Language('hu', '🇭🇺 Magyar')
]

interface IAppProps {
    user?: User
    firebase: Firebase
}
interface IAppState {
    browserLanguage: string
    language?: string
}
class App extends React.Component<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props)

        const browserLocale = (navigator.languages && navigator.languages[0]) || navigator.language || "en-US"
        let browserLanguage = browserLocale.split(/[-_]/)[0]
        if (!Object.keys(messages).includes(browserLanguage)) {
            browserLanguage = "en"
        }

        this.state = {
            browserLanguage: browserLanguage,
            language: undefined
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
    }

    handleScroll = () => {
        if (window.scrollY > 25) {
            document.querySelector("#page-wrap-spacer")!.className = "page-wrap-spacer-solid"
        } else {
            document.querySelector("#page-wrap-spacer")!.className = "page-wrap-spacer-transparent"
        }
    }

    setLanguage = (languageId: string) => {
        this.setState({ language: languageId })
        this.props.firebase.setAuthLocale(languageId)
        if (this.props.user?.uid) {
            this.props.firebase.setUserLocale(this.props.user.uid, languageId)
        }
    }

    render() {
        const locale = (this.state.language || (this.props.user && this.props.user.locale) || this.state.browserLanguage) as "cs" | "en"

        return (
            <IntlProvider locale={locale} defaultLocale={i18nConfig.defaultLocale} messages={i18nConfig.messages[locale]}>
                <ThemeProvider theme={muiTheme}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                        <Router>
                            <Navigation setLanguage={this.setLanguage} languages={languages} selectedLanguageId={locale} />
                            <div id="page-wrap-spacer">
                                <img className="navigation-logo d-md-none" src={logo} alt="logo" />
                            </div>
                            <div id="page-wrap">
                                <Suspense
                                    fallback={
                                        <div className="caption-container">
                                            <br />
                                            <div className="loader"></div>
                                        </div>
                                    }
                                >
                                    <CookieBanner selectedLanguageId={locale} />
                                    <Routes>
                                        {/*<Route exact path={ROUTES.PARKING} component={MyParking} />*/}
                                        <Route path={ROUTES.RESERVE} element={<ReserveSpot />} />
                                        <Route path={ROUTES.CANCEL_RESERVATION} element={<CancelReservationPage />} />
                                        <Route path={ROUTES.AUTHORIZE_SLACK} element={<AuthorizeSlackPage />} />
                                        <Route path={ROUTES.RESERVATIONS_OVERVIEW} element={<ReservationsOverview />} />

                                        <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
                                        <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
                                        <Route path={ROUTES.PROFILE} element={<Profile isCarpooling={false} />} />
                                        <Route path={ROUTES.EDIT_PROFILE} element={<EditProfile />} />
                                        <Route path={ROUTES.CARS} element={<CreateCars />} />

                                        <Route path={ROUTES.ADMIN} element={<AdminPage />} />
                                        <Route path={ROUTES.VISITORS_BOOKING} element={<VisitorsBookingPage />} />
                                        <Route path={ROUTES.VISITORS_BOOKING_PREVIEW} element={<VisitorsBookingPreviewPage />} />
                                        <Route path={ROUTES.ANALYTICS} element={<AnalyticsPage />} />
                                        <Route path={ROUTES.ABUSE_REPORT} element={<AbuseReportPage />} />
                                        <Route path={ROUTES.USER_MANAGEMENT} element={<UserManagement />} />
                                        <Route path={ROUTES.PARKING_MANAGEMENT} element={<ParkingManagement />} />

                                        <Route path={ROUTES.ONBOARDING} element={<Onboarding />} />

                                        <Route path={ROUTES.ABOUT} element={<AboutPage />} />
                                        <Route path={ROUTES.CONTACTS} element={<ContactsPage isCarpooling={false} />} />

                                        <Route path={ROUTES.KIWI_RULES} element={<KiwiRules />} />

                                        <Route path={ROUTES.SAP} element={<SAP />} />

                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </Suspense>
                            </div>
                            {/*<Footer />*/}
                        </Router>

                        {/*Footer*/}
                    </LocalizationProvider>
                </ThemeProvider>
            </IntlProvider>
        )
    }
}
interface IAppBaseProps {
    firebase: Firebase
}
class AppBase extends React.Component<IAppBaseProps, any> {
    render() {
        return (
            <AuthUserContext.Consumer>
                {userInfo => {
                    return <App user={userInfo.user} {...this.props} />
                }}
            </AuthUserContext.Consumer>
        )
    }
}

export default withFirebase(withAuthentication(AppBase))
