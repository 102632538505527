import { DocumentSnapshot } from "../firestoreImports.js"

interface ParkingSpotParameters {
    id: SpotId
    name: string
    electric: boolean
    imageUrl?: string
    attractivity: number
    naturalGasAllowed: boolean
    enabled: boolean
    inReserve: boolean
}
export type SpotId = string
export class ParkingSpot {
    id: SpotId
    name: string
    electric: boolean
    imageUrl?: string
    attractivity: number
    naturalGasAllowed: boolean
    enabled: boolean
    inReserve: boolean

    constructor(parameters: ParkingSpotParameters) {
        this.id = parameters.id
        this.name = parameters.name
        this.electric = parameters.electric
        this.imageUrl = parameters.imageUrl
        this.attractivity = parameters.attractivity
        this.naturalGasAllowed = parameters.naturalGasAllowed
        this.enabled = parameters.enabled
        this.inReserve = parameters.inReserve
    }

    static fromData(doc: DocumentSnapshot): ParkingSpot | null {
        if (!doc.data()) {
            console.warn("Cannot create ParkingSpot from snapshot")
            return null
        }

        const data = doc.data()!
        if (!data.name) {
            console.warn(`Cannot create ParkingSpot from snapshot, missing 'name'`, data)
            return null
        }

        const spot = new ParkingSpot({
            id: doc.id,
            name: data.name,
            electric: data.electric ?? false,
            imageUrl: data.imageUrl,
            attractivity: data.attractivity ?? 0,
            naturalGasAllowed: data.naturalGasAllowed ?? true,
            enabled: data.isEnabled ?? true,
            inReserve: data.inReserve ?? false
        })
        return spot
    }

    static compare(a: ParkingSpot, b: ParkingSpot) {
        if (a.attractivity === b.attractivity) return a.name.localeCompare(b.name)
        else return b.attractivity - a.attractivity
    }

    public toString = (): string => {
        return `${this.name} (${this.id})`
    }
}
