import Firebase, { withFirebase } from "components/Firebase"
import { Fade } from "react-awesome-reveal"
import WebAPI from "components/Firebase/webAPI"
import { useEffect, useState } from "react"
import axios, { CanceledError } from "axios"
import "styles/sap.css"
import OccupancyCard from "otherFiles/occupancyCard"

enum SapLocation {
    Tower = "ijHI1xRGvmdyyepob8r9",
    Iq = "sap-brno"
}

interface IReserveSpotBaseProps {
    firebase: Firebase
}

const SAPBase = (props: IReserveSpotBaseProps) => {
    const [towerOccupancyForDay, setTowerOccupancyForDay] = useState<number | undefined>(undefined)
    const [iqOccupancyForDay, setIqOccupancyForDay] = useState<number | undefined>(undefined)

    const fetchOccupancy = (type: SapLocation, setOccupancy: React.Dispatch<React.SetStateAction<number | undefined>>, date: Date) => {
        const source = axios.CancelToken.source()
        WebAPI.getOccupancy(type, date, undefined, source.token)
            .then(result => {
                setOccupancy(result.remainingSpots)
            })
            .catch(error => {
                if (error instanceof CanceledError) {
                    console.log(`Canceling occupancy request for ${type}`)
                } else {
                    console.warn(`Error getting occupancy for ${type} on date:`, error)
                }
            })
    }

    useEffect(() => {
        const startTime = new Date()

        fetchOccupancy(SapLocation.Tower, setTowerOccupancyForDay, startTime)
        fetchOccupancy(SapLocation.Iq, setIqOccupancyForDay, startTime)
    }, [])

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
            {towerOccupancyForDay && iqOccupancyForDay !== undefined ? (
                <div className="d-flex flex-column flex-lg-row mobile-gap gap-lg-3">
                    <OccupancyCard title="Tower" occupancyForDay={towerOccupancyForDay} />
                    <OccupancyCard title="IQ" occupancyForDay={iqOccupancyForDay} />
                </div>
            ) : (
                <div className="spinner-border text-primary" role="status" />
            )}
        </div>
    )
}

const SAP = (props: any) => {
    return (
        <div className="parkfair-container">
            <h3>SAP</h3>
            <hr className="d-none d-md-block" />
            <Fade>
                <SAPBase {...props} />
            </Fade>
        </div>
    )
}

export default withFirebase(SAP)
