import FirebaseContext, { withFirebase } from "./context"
import Firebase, { Timestamp, areSameDay } from "./firebase"
import { Reservation, PermanentReservation, ReservationStatus } from "./Models/reservation"
import { User, UserPriorityLevel } from "./Models/user"
import { Car, EngineType } from "./Models/car"
import { ParkingSpot } from "./Models/parkingSpot"
import { AbuseInfo } from "./Models/abuse"
import {
    ApiError,
    ValidationResult,
    DomainValidationResult,
    ReservationResult,
    OccupancyResult,
    ApiErrorCode,
    ReservationResultState
} from "./Models/webApi"
import { Location, ReservationType } from "./Models/location"
import { Company } from "./Models/company"
import { Collections, Language } from "./Models/other"

export default Firebase

export {
    FirebaseContext,
    withFirebase,
    ApiError,
    ApiErrorCode,
    Timestamp,
    Reservation,
    User,
    Car,
    EngineType,
    ParkingSpot,
    areSameDay,
    UserPriorityLevel,
    AbuseInfo,
    Location,
    ReservationType,
    ReservationResultState,
    Collections,
    ReservationStatus,
    Language,
    Company,
    PermanentReservation,
    ReservationResult,
    OccupancyResult
}
export type { ValidationResult, DomainValidationResult }
