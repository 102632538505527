import React from "react"
import { Link } from "react-router-dom"

import * as ROUTES from "constants/routes"

const NotFound = () => (
    <div className="caption-container">
        <br />
        <br />
        <br />
        <h1>Oooops!</h1>
        <h5>The page you were looking for was not found on the server</h5>
        <br />
        <Link to={ROUTES.RESERVE}>
            <button type="submit" className="btn hm-btn btn-primary">
                Take me home
            </button>
        </Link>
    </div>
)

export default NotFound
