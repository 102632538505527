import React, { useEffect, useState, useCallback } from "react"
import { Fade } from "react-awesome-reveal"
//import 'moment/locale/cs'
import "styles/day-picker.scss"

import Firebase, { withFirebase, Reservation, User } from "../Firebase"
import { UserStateCheck } from "../../shared-components/Session"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import moment from "moment"

interface IReservationsOverviewBase {
    firebase: Firebase
    user: User
}

const ReservationsOverviewBase = (props: IReservationsOverviewBase) => {
    const [reservations, setReservations] = useState<Reservation[] | undefined>(undefined)
    const timezone = () => {
        return props.user.activeLocation?.timezone ?? "Europe/Prague"
    }

    const onSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.name === "location") {
            props.user.activeLocationId = event.target.value
            setReservations(undefined)
            await props.firebase.setUserActiveLocation(props.user, event.target.value)
            updateData()
        }
    }

    const fetchCurrentReservations = useCallback(() => {
        if (props.user.activeLocation === undefined) {
            console.warn("User has no activeLocation!")
            return Promise.resolve()
        }

        const startOfToday = moment.tz(props.user.activeLocation.timezone).startOf("d").toDate()
        const endOfToday = moment.tz(props.user.activeLocation.timezone).endOf("d").toDate()
        props.firebase
            .fetchAllReservations(props.user.activeLocation, startOfToday, endOfToday)
            .then(reservations => reservations.filter(r => r.confirmed === true && r.canceled !== true))
            .then(reservations => reservations.sort((a, b) => a.parkingSpot?.localeCompare(b.parkingSpot ?? "") ?? 0))
            .then(props.firebase.fetchUsersForReservations)
            .then(setReservations)
            .catch(error => {
                console.warn("Error fetching reservations for date: ", error)
            })
    }, [props.firebase, props.user])

    const updateData = useCallback(() => {
        fetchCurrentReservations()
    }, [fetchCurrentReservations])

    useEffect(() => {
        updateData()
    }, [updateData])

    if (reservations === undefined) {
        return <div className="loader"></div>
    }

    const reservationsBlock = (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Parking spot</TableCell>
                        <TableCell>License plate</TableCell>
                        <TableCell>Start time</TableCell>
                        <TableCell>End time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reservations?.map(r => {
                        return (
                            <TableRow key={r.id}>
                                <TableCell>{r.parkingSpot}</TableCell>
                                <TableCell>
                                    {r.user?.cars?.find(c => c.id === r.carId)?.plateNum ?? r.user?.cars?.map(c => c.plateNum).join(", ")}
                                </TableCell>
                                <TableCell>{moment.tz(r.startTime, timezone()).format("LT")}</TableCell>
                                <TableCell>{moment.tz(r.endTime, timezone()).format("LT")}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )

    return (
        <div>
            {props.user.locations!.length > 1 ? (
                <div>
                    <span className="m-2">Location:</span>
                    <select
                        className="mb-3 select_arrow y-style"
                        name="location"
                        id="location"
                        value={props.user.activeLocationId}
                        onChange={onSelectChange}
                    >
                        {props.user.locations!.map(location => (
                            <option key={location.code} value={location.code}>
                                {location.title}
                            </option>
                        ))}
                    </select>
                </div>
            ) : (
                <div></div>
            )}
            <Row className="m-0">
                <Col>{reservationsBlock}</Col>
            </Row>
        </div>
    )
}

const ReservationsOverview = (props: any) => (
    <div className="parkfair-container">
        <h3>Overview of current reservations</h3>
        <hr className="d-none d-md-block" />
        <UserStateCheck>
            {userInfo => (
                <Fade>
                    <ReservationsOverviewBase user={userInfo.user} {...props} />
                </Fade>
            )}
        </UserStateCheck>
    </div>
)

export default withFirebase(ReservationsOverview)
