import axios, { CancelToken } from "axios"
import {
    Car,
    ApiError,
    ValidationResult,
    DomainValidationResult,
    User,
    ReservationResult,
    ReservationResultState,
    ApiErrorCode,
    OccupancyResult
} from "."

export default class WebAPI {
    static apiAddress = process.env.REACT_APP_WEB_API + "/webApi/api/v1"

    static validateSignUpCode = (code: string): Promise<ValidationResult> => {
        return axios
            .post(WebAPI.apiAddress + "/validateSignUpCode", {
                signUpCode: code
            })
            .then(response => {
                if (response.status === 200 && response.data) {
                    const data = response.data
                    if (data.valid && data.company && data.locationIds) {
                        return Promise.resolve({ valid: true, company: data.company, locationIds: data.locationIds })
                    }
                    return Promise.resolve({ valid: false })
                } else {
                    return Promise.reject(`Response status code ${response.status}, ${response.data}`)
                }
            })
            .catch(error => {
                console.log("Error validating code: ", error)
                return Promise.reject(new Error("Error validating code"))
            })
    }

    static validateSignUpDomain = (domain: string) => {
        return axios
            .post(WebAPI.apiAddress + "/validateSignUpDomain", {
                signUpDomain: domain
            })
            .then(response => {
                if (response.status === 200 && response.data) {
                    const data = response.data
                    return WebAPI.processValidationResponse(data)
                } else {
                    return Promise.reject(`Response status code ${response.status}, ${response.data}`)
                }
            })
            .catch(error => {
                console.log("Error validating domain: ", error)
                return Promise.reject(`Error validating domain`)
            })
    }

    private static processValidationResponse = (data: any): Promise<DomainValidationResult> => {
        if (data.valid && data.company && data.locationIds) {
            return Promise.resolve({
                valid: true,
                company: data.company,
                locationIds: data.locationIds,
                ssoProviderId: data.ssoProviderId,
                passwordEnabled: data.passwordEnabled ?? true
            })
        }
        return Promise.resolve({ valid: false })
    }

    static reserveSpot = (user: User, startDate: Date, endDate: Date, car: Car, preventDuplicite: boolean): Promise<ReservationResult> => {
        if (user.activeLocation === undefined) {
            return Promise.reject(new Error("User has no activeLocation!"))
        }
        return axios
            .post(WebAPI.apiAddress + "/reserveSpot", {
                userId: user.uid,
                locationId: user.activeLocation.code,
                startDate: startDate.getTime() / 1000,
                endDate: endDate.getTime() / 1000,
                priority: user.priority,
                carId: car.id,
                engineType: car.engineType,
                preventDuplicite: preventDuplicite ? 1 : 0
            })
            .catch(error => {
                console.log("Error reserving spot: ", error)
                if (error.response) {
                    console.log(error.response.data)
                }
                return Promise.reject(`Error booking spot`)
            })
            .then(response => {
                if (response.status !== 200 || !response.data) {
                    return Promise.reject(`Response status code ${response.status}, ${response.data}`)
                }
                const data = response.data
                if (!data.status || data.status !== "OK") {
                    if (data.errorCode) {
                        return Promise.reject(new ApiError("Reserve spot error " + ApiErrorCode[data.errorCode], data.errorCode))
                    }
                    return Promise.reject(`Unknown reserve spot error:  ${data}`)
                }

                if (data.confirmed === true && data.parkingSpot && data.parkingSpotId && data.reservationId) {
                    return Promise.resolve(
                        new ReservationResult(ReservationResultState.Confirmed, data.parkingSpot, data.parkingSpotId, data.reservationId)
                    )
                } else if (data.confirmed === false) {
                    return Promise.resolve(new ReservationResult(ReservationResultState.Declined))
                } else {
                    return Promise.resolve(new ReservationResult(ReservationResultState.Pending, undefined, undefined, data.reservationId))
                }
            })
    }

    static getOccupancy = (
        locationCode: string,
        startDate: Date,
        endDate: Date | undefined = undefined,
        cancelToken: CancelToken | undefined = undefined
    ): Promise<OccupancyResult> => {
        return axios
            .post(
                WebAPI.apiAddress + "/getOccupancy",
                {
                    locationId: locationCode,
                    startDate: startDate.getTime() / 1000,
                    endDate: (endDate ?? startDate).getTime() / 1000
                },
                { cancelToken: cancelToken }
            )
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data)
                }
                return Promise.reject(error)
            })
            .then(response => {
                if (response.status !== 200 || !response.data) {
                    return Promise.reject(`Response status code ${response.status}, ${response.data}`)
                }
                const data = response.data
                if (!data.status || data.status !== "OK") {
                    if (data.errorCode) {
                        return Promise.reject(new ApiError("Get occupancy error " + ApiErrorCode[data.errorCode], data.errorCode))
                    }
                    return Promise.reject(`Unknown get occupancy error:  ${data}`)
                }

                if (data.remainingSpots !== undefined && data.confirmedReservations !== undefined && data.pendingReservations !== undefined) {
                    return Promise.resolve(new OccupancyResult(data.remainingSpots, data.confirmedReservations, data.pendingReservations))
                }
                return Promise.reject(`Get occupancy error: data demangling failed ${data}`)
            })
    }

    static getSlackAccessToken = (code: string) => {
        return axios
            .post(
                "https://slack.com/api/oauth.v2.access",
                {
                    code: code,
                    client_id: "609999784001.3962879066695",
                    client_secret: "b630924c6b68e53e863873b0197e74d0"
                },
                {
                    headers: { "Content-Type": "multipart/form-data" }
                }
            )
            .catch(error => {
                console.log("Error geting access token: ", error)
                if (error.response) {
                    console.log(error.response.data)
                }
                return Promise.reject(`Authorization failed`)
            })
            .then(response => {
                if (response.status === 200 && response.data) {
                    const data = response.data
                    if (data.ok && data.ok === true && data.access_token && data.authed_user && data.authed_user.id) {
                        return Promise.resolve([data.access_token, data.authed_user.id])
                    } else if (data.error) {
                        console.log(data.error)
                        return Promise.reject(new ApiError(`Authorization failed (${data.error})`))
                    } else {
                        return Promise.reject(new ApiError("Authorization failed"))
                    }
                } else {
                    return Promise.reject(`Response status code ${response.status}, ${response.data}`)
                }
            })
    }

    static createCompany = (userId: string, email: string, domain: string | undefined, companyName: string, phone?: string): Promise<void> => {
        return axios
            .post(WebAPI.apiAddress + "/createCompany", {
                userId: userId,
                email: email,
                domain: domain,
                companyName: companyName,
                phone: phone
            })
            .catch(error => {
                console.log("Error creating company: ", error)
                if (error.response) {
                    console.log(error.response.data)
                }
                return Promise.reject(`Error creating company`)
            })
            .then(response => {
                if (response.status !== 200 || !response.data) {
                    return Promise.reject(`Response status code ${response.status}, ${response.data}`)
                }
                const data = response.data
                if (!data.status || data.status !== "OK") {
                    if (data.errorCode) {
                        return Promise.reject(new ApiError("Creating company error " + ApiErrorCode[data.errorCode], data.errorCode))
                    }
                    return Promise.reject(`Unknown create company error:  ${data}`)
                }
                return Promise.resolve()
            })
    }
}
