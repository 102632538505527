import React from "react"
import Webcam from "react-webcam"

const videoConstraints = {
    /*width: 1280,
  height: 720,*/
    facingMode: "environment"
}

interface IWebcamCaptureProps {
    capturedImageData: (imgData: string) => void
}
export const WebcamCapture = (props: IWebcamCaptureProps) => {
    const webcamRef = React.useRef(null)
    const [imgSrc, setImgSrc] = React.useState<string | null>(null)

    const capture = React.useCallback(() => {
        const imageSrc = (webcamRef.current as any)?.getScreenshot()
        props.capturedImageData(imageSrc)
        setImgSrc(imageSrc)
    }, [webcamRef, setImgSrc, props])

    const clear = React.useCallback(() => {
        setImgSrc(null)
    }, [setImgSrc])

    return (
        <>
            {imgSrc ? (
                <>
                    <img src={imgSrc} alt="Capture" />
                    <button type="button" className="btn btn-primary hm-btn m-2" onClick={clear}>
                        Retake
                    </button>
                </>
            ) : (
                <>
                    <Webcam
                        audio={false}
                        height={"100%"}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={"100%"}
                        videoConstraints={videoConstraints}
                    />
                    <button type="button" className="btn btn-primary hm-btn m-2" onClick={capture}>
                        Capture photo
                    </button>
                </>
            )}
        </>
    )
}
